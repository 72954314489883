import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs/Rx';

import { CommonService } from '../../services/common.service';
import { UtilityService } from '../../services/utility.service';
import { ModalService } from '../../services/modal.service';
import { AuthService } from '../../services/authentication.service';
import { AppStateService } from '../../services/app-state.service';
import { SocketService } from '../../services/socket.service';
import { PreloaderService } from '../../services/preloader.service';

import { AccountApi, ProjectApi, RecordTypeApi, ReleaseNotesApi, WorkerApi, UsersApi } from '../../sdk';
import { environment } from '../../../environments/environment';
import { MaintenanceScheduleApi } from 'shared/sdk/services/custom/MaintenanceSchedule';
import { MaintenanceScheduleInterface } from 'shared/sdk';

@Component({
    selector: 'app-nav-header',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    providers: [ModalService, UtilityService]
})

export class NavigationComponent implements OnInit, OnDestroy {

    user: any;
    imgpath: any;
    accessType = '';
    webTitle = 'ServiceO';
    notification: number;
    viewType = '';
    render = '/pms';
    toggleSwitch: any;
    private subscription: Subscription;
    private subscription1: Subscription;
    @ViewChild('openModel') openModel: ElementRef;
    @ViewChild('profileOpenModal') profileOpenModal: ElementRef;
    @ViewChild('maintenanceModal') maintenanceModal: ElementRef;
    @ViewChild('createTaskModal') createTaskModal: ElementRef;
    accounts = [];
    workersList = [];
    allWorkersList = [];
    vendor: any;
    tableData = [];
    noRecords: any;
    itemsPerPage = 10;
    itemsPerBatch = 200;
    loadingIndicator: any;
    recordTypeId = '0121a000000QaUYAA0';
    txtAccountName = '';
    public keyUp = new Subject<string>();
    userTypeSlug = '';
    viewAsTypeName: string;
    programSelection = false;
    partnerInfo: any;
    viewAsInfo: any;
    workerType = '';
    isMaintenanceButtonVisible = false;
    maintenanceAlertColor = false;
    isMagicLink = false;
    hideNotification: any;
    isVendorWorkerSelected = false;
    vendorAccessType: any;
    viewAsVendor = false;
    isVendorView = false;
    isNgo = false;
    partnerIsAdmin = false;
    openedDialog = false;
    isViewAsPMS = false;
    isViewAsPMSA = false;
    newCountReleaseNotes = 0;
    showReleaseCounts: boolean = false;
    public workerKeyUp = new Subject<string>();
    workerTableData: any= [];
    workerTxtAccountName= '';
    showVendorListing = true;
    showWorkerListing= false;
    totalWorkerCount = 0;
    wikiURL = 'https://wiki.serviceglobal.com/wiki/serviceo/view/WebApp/'
    workerIDOrEmail=[];
    constructor(
        private _location: Location,
        private _cdRef: ChangeDetectorRef,
        private _router: Router,
        private authSerice: AuthService,
        private _preloader: PreloaderService,
        private _appState: AppStateService,
        private _utilityService: UtilityService,
        private commonService: CommonService,
        private _modalService: ModalService,
        private _socketService: SocketService,
        private accountApi: AccountApi,
        private workerApi: WorkerApi,
        private _commonService: CommonService,
        private _recordType: RecordTypeApi,
        private _projectApi: ProjectApi,
        private _maintenanceApi: MaintenanceScheduleApi,
        private _releaseNotesApi: ReleaseNotesApi,
        private _usersApi: UsersApi
    ) { }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this._router.url.indexOf('pms') !== -1) {
            localStorage.removeItem('ImpersonationId');
            this._appState.setNavAsVendor(null);
        }
    }

    ngOnInit() {
        const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
        this.isVendorView = (viewAsInfo && viewAsInfo.viewAsType === 'Vendor');
        this.isViewAsPMS = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
        this.isViewAsPMSA = (viewAsInfo && viewAsInfo.viewAsType === 'Partner' && viewAsInfo.isAdmin);
        this.hideNotification = environment.hideNotification;
        this.imgpath = environment.baseUrl + '/' + environment.apiVersion;
        this.accessType = this._appState.getAccessType();
        const appData = JSON.parse(localStorage.getItem('appData'));
        this.isMagicLink = appData.user && appData.user.isMagiclinkTest ? appData.user.isMagiclinkTest : false;
        this.isNgo = appData.user && appData.user.isNgo ? appData.user.isNgo : false;
        this.userTypeSlug = this._appState.getUserTypeSlug();
        if (this.accessType === 'internal') {
            this.CheckMaintenanceModal();
        }
        // console.log(this.userTypeSlug)
        // Total notification autoupdate after event
        this.getNotificationsCount();
        // set current view name
        this.setCurrentView();
        // set home page url
        this.setHomePageUrl();
        // get current user
        this.setCurrentUser();
        // init server side search for account
        this.initAccountSearch();
        //init server side search for worker list
        this.initWorkerSearch();
        // check vendor view
        this.checkVendorView();
        // check if view As mode is active
        this.checkViewAs();
        // get releasenotesCount
        this.getReleaseNotes();
    }

    CheckMaintenanceModal() {
        if (localStorage.getItem('maintenanceModalViewed') === 'true') {
            this.isMaintenanceButtonVisible = true;
        }
        if (localStorage.getItem('maintenanceAlertColor') === 'true') {
            this.maintenanceAlertColor = true;
        }
        var filter = {
            where: {
                isActive: true,
                isEnabled: true,
                endDate: { gt: new Date() }
            },
            order: "startDate ASC",
            limit: 1
        }
        this._maintenanceApi.find(filter).subscribe((res: MaintenanceScheduleInterface[]) => {

            if (res && res.length && Object.keys(res[0]).length) {
                let schedule = res[0];

                if (schedule.startDate && schedule.endDate && (new Date(schedule.startDate) < new Date(schedule.endDate))) {
                    let calculatedStartDate = new Date(schedule.startDate);
                    calculatedStartDate = new Date(calculatedStartDate.setHours(calculatedStartDate.getHours() - 48));

                    if (new Date(schedule.endDate) > new Date() && new Date() > new Date(schedule.startDate)) {
                        this.maintenanceAlertColor = true;
                        localStorage.setItem('maintenanceAlertColor', 'true');
                    } else {
                        this.maintenanceAlertColor = false;
                        localStorage.removeItem('maintenanceAlertColor');
                    }

                    if (new Date(schedule.endDate) > new Date() && new Date() > calculatedStartDate) {
                        this.isMaintenanceButtonVisible = true;
                        if (localStorage.getItem('maintenanceModalViewed') !== 'true') {
                            this.openMaintenanceModal();
                        }
                    } else {
                        this.resetMaintenanceModal();
                    }

                } else {
                    this.resetMaintenanceModal();
                }

            } else {
                this.resetMaintenanceModal();
            }

        });
    }

    resetMaintenanceModal() {
        this.isMaintenanceButtonVisible = false;
        this.maintenanceAlertColor = false;
    }

    openMaintenanceModal() {
        this._modalService.open(this.maintenanceModal);
    }

    checkViewAs() {
        this.viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
    }

    getNotificationsCount() {
        this.subscription = this._socketService.getCountNotification().subscribe(
            unreadNotification => {
                this.notification = unreadNotification;
            }
        );
    }

    setCurrentView() {
        this.viewType = '';
        if (this._location.path().indexOf('vms') !== -1 || this._location.path().indexOf('app') !== -1) {
            this.viewType = 'vms';
            this.toggleSwitch = { title: 'Switch to PMS', url: '/pms' };
        } else {
            this.viewType = 'pms';
            this.toggleSwitch = { title: 'Switch to VMS', url: '/vms' };
        }
    }

    setHomePageUrl() {
        this.render = this._appState.getHomeUrl();
        if (!this.render || this.render === '') {
            this.render = '/login';
        }
    }

    setCurrentUser() {
        this.commonService.getUserProfile().subscribe(
            userProfile => {
                this.user = userProfile;
            }
        );
        // set user profile
        if (this.user && this.user['id']) {
            this.accessType = this.user['accessType'];
            this.workerType = this.user['workers'] && this.user['workers'].length ? this.user['workers'][0].Worker_Type__c : '';
            this.getProfileImage();
        } else {
            this._appState.getAppState().subscribe(
                data => {
                    if (data && data['user']) {
                        this.accessType = data['user'].accessType;
                        this.user = data['user'];
                        this.workerType = data['user']['workers'] && data['user']['workers'].length ? data['user']['workers'][0].Worker_Type__c : '';
                    }
                    this.getProfileImage();
                }
            );
        }
    }

    getProfileImage() {
        this.setProfile();
        /* to open password modal to indicate user to change password */
        if (this.user.promptPasswordChange) {
            this.openModel.nativeElement.click();
            this.detectChanges();
        }

        if (!this.openedDialog && !this.user.profileImage && !this.user.promptPasswordChange && (this.accessType === 'internal' || this.accessType === 'vendor') && !this.user.skipProfilePic ) {
            this.profileOpenModal.nativeElement.click();
            this.openedDialog = true;
            this.detectChanges();
        }
    }

    setProfile() {
        this.commonService.setUserProfile(this.user);
    }

    detectChanges() {
        if (!this._cdRef['destroyed']) {
            this._cdRef.detectChanges();
        }
    }

    initAccountSearch() {
        const observable = this.keyUp
            .map(value => value['target']['value'].toLowerCase())
            .debounceTime(300)
            // .distinctUntilChanged()
            .flatMap((search) => {
                return Observable.of(search).delay(300);
            })
            .subscribe((data) => {
                if (this.programSelection) {
                    this.getProgramList(0, false);
                } else {
                    this.getAccount(0, false);
                }
            });
    }

    checkVendorView() {
        this.vendor = null;
        if (this.accessType === 'internal' && (this.viewType === 'vms' || this.viewType === 'pms') && this.isVendorView) {
            if (this.vendor && this.vendor['sfdcId']) {
                this.setVendorView(this.vendor);
            } else {
                const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
                this.workerApi.find({
                    where: { Contact__c: viewAsInfo['userSfdcId'] },
                    fields: ['id', 'sfdcId', 'Name', 'Dispatch_Worker_num__c', 'createdAt']
                }).subscribe(result => {
                    if (result && result.length) {
                        this.setVendorView(result[0]);
                    } else {
                        this.setVendorView(null);
                    }
                }, err => {
                    this.setVendorView(null);
                });
            }
        } else {
            this.setVendorView(null);
        }
    }

    setVendorView(vendor) {
        this.vendor = vendor;
        if (vendor) {
            localStorage.setItem('ImpersonationId', vendor['sfdcId']);
        } else {
            localStorage.removeItem('ImpersonationId');
        }
        this._appState.setNavAsVendor(this.vendor);
    }

    showVendorView(account) {
        this.getWorkerList(account['sfdcId']);
        this.txtAccountName = '';
        //this.workerTxtAccountName = '';
    }


    getWorkerList(accountId: number, offset = 0, isLoadMore = false) {
        this.loadingIndicator = true;
        this._preloader.showPreloader();
        this.workerApi.find({
            fields: ['id', 'sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Email__c', 'Contact__c', 'Account__c', 'Worker_Type__c'],
            include: [
                {
                    relation: 'user',
                    scope: {
                        fields: ['id', 'sfdcId', 'Name', 'accessType', 'userTypeId', 'isTest', 'isMagiclinkTest', 'isNgo', 'firstname', 'lastname', 'AccountId'],
                        include: [
                            {
                                relation: 'userType',
                                scope: {
                                    fields: ['name', 'slug']
                                }
                            },
                            {
                                relation: 'account',
                                scope: {
                                    fields: ['id', 'Name', 'Primary_VCSQDLead_Ref__c', 'Default_Pricelist__c', 'CurrencyIsoCode'],
                                    include: [{
                                        relation: 'accountRFI',
                                        scope: {
                                            fields: ['Iron_Status__c']
                                        }
                                    }]
                                }
                            },
                            {
                                relation: 'contact',
                                scope: {
                                    fields: ['id', 'Send_RFI_Email__c', 'UserId', 'LastLogin__c', 'ReportsToId', 'Contact_Name__c', 'Department'],
                                    include: [{
                                        relation: 'reportingManagerList',
                                        scope: {
                                            fields: ['sfdcId', 'Name']
                                        }
                                    }]
                                }
                            }
                        ]
                    }
                }
            ],
            limit: this.itemsPerBatch,
            where: { Account__c: accountId },
            skip: offset
        }).subscribe(workers => {
            this.noRecords = (workers.length < this.itemsPerBatch) ? true : false;
            workers = workers.filter((item: any) => item && item.user && item.user.sfdcId);
            if (!isLoadMore) {
                this.workersList = workers;
            } else {
                workers.forEach(c => {
                    this.workersList.push(c);
                });
                this.workersList = [...this.accounts];
            }
            this.isVendorWorkerSelected = true;
            this.setDataTable();
        }, err => {
            this.loadingIndicator = false;
            this._preloader.hidePreloader();
        })
    }

    selectWorker(row) {
        this.setVendorView(row);
        this.viewAsVendor = true;
        this.vendorAccessType = {
            viewAsType: this.viewAsTypeName,
            accessType: row.user['accessType'],
            workerType: row.Worker_Type__c,
            userTypeSlug: row.user.userType.slug,
            isMagicLink: row.user.isMagiclinkTest,
            isNgo: row.user.isNgo,
            isTest: row.user.isTest,
            userSfdcId: row.user.sfdcId,
            accountId: row.Account__c,
            workerSfdcId: row.sfdcId,
            firstName: row.user.firstname,
            lastName: row.user.lastname,
            userObj: row.user
        }
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        filterObj['accounts'] = [row.Account__c];
        localStorage.setItem('filterObj', JSON.stringify(filterObj));
        filterCondition['Vendor__c'] = { inq: [row.Account__c] };
        delete filterCondition['Project_SOP__c'];
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
        localStorage.setItem('viewAsInfo', JSON.stringify(this.vendorAccessType));

        this._modalService.closed();
        this.redirectTo(row);
        // window.location.reload();
    }

    showPartnerView(program) {
        this.setViewAsPartnerInfo(program);
        this._modalService.closed();
        window.location.replace(window.location.origin + '/vms');
    }

    setViewAsPartnerInfo(selectedProgram) {
        const viewAsInfo = {
            account: this.partnerInfo,
            program: selectedProgram,
            recordTypeId: this.recordTypeId,
            viewAsType: this.viewAsTypeName,
            isAdmin : this.partnerIsAdmin
        }
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        filterObj['accounts'] = [viewAsInfo['account']['sfdcId']];
        // delete filterObj['programs'];
        filterObj['programs'] = [viewAsInfo['program']['sfdcId']];
        localStorage.setItem('filterObj', JSON.stringify(filterObj));
        // filterCondition['CKSW_BASE__Account__c'] = { inq: [viewAsInfo['account']['sfdcId']] };
        // delete filterCondition['Project_SOP__c'];
        filterCondition['CKSW_BASE__Account__c'] = { inq: [[viewAsInfo['account']['sfdcId']]]};
        filterCondition['Project_SOP__c'] = { inq: [[viewAsInfo['program']['sfdcId']]]};
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
        localStorage.setItem('viewAsInfo', JSON.stringify(viewAsInfo));
    }

    closeVendorView($event) {
        this.setVendorView(null);
        localStorage.removeItem('viewAsInfo');
        window.location.replace(window.location.origin + '');
    }

    async logout() {
        const saveAnalytics = await this.saveAnalytics();
        localStorage.clear();
        this.authSerice.logout();
    }

    onLogoClick() {
        if (this.accessType === 'partner') {
            this._router.navigate(['/pms']);
        } else if (this.accessType === 'vendor') {
            this._router.navigate(['/vms']);
        } else if (this.accessType === 'internal') {
            if (this.viewType === 'vms') {
                this._router.navigate(['/vms']);
            } else if (this.viewType === 'pms') {
                this._router.navigate(['/pms']);
            }
        }
    }

    open(content, size, src) {
        this.showVendorListing = true;
        this.showWorkerListing= false;
        this.programSelection = false;
        this.txtAccountName = '';
        this.workerTxtAccountName = '';
        this.tableData = [];
        this.workerTableData = [];
        this._modalService.open(content, size);
        if (src === 'viewAsVendor') {
            this.totalWorkerCount = 0;
            this.viewAsTypeName = 'Vendor';
            this.setRecordTypeId('Vendor');
            this.getAllWorkerList();
        } else if (src === 'viewAsPartner') {
            this.viewAsTypeName = 'Partner';
            this.setRecordTypeId('Partner');
        }
    }

    setRecordTypeId(type: string) {
        const filter = {
            where: { and: [{ Name: type }, { SobjectType: 'Account' }] },
            fields: ['sfdcId']
        }
        this._recordType.find(filter).subscribe(res => {
            this.recordTypeId = res[0]['sfdcId'];
            this.getAccount(0, false);
        }, err => { });
    }

    gotToOrderList() {
        if (this._router.url.indexOf('fse-console') === -1) {
            this._router.navigate(['pms/service-manager/fse-console']);
        } else {
            window.location.reload();
        }
    }

    getReleaseNotes() {
        this._releaseNotesApi.getNewCountReleasenotes({}).subscribe(res => {
            this.newCountReleaseNotes = res['count'];
            let today = new Date();
            var differeceinDay = (today.getTime() - new Date(this._commonService.utcDateFormate(res['buildDate'],"MM/DD/YYYY")).getTime())/(1000*3600*24);
            if(differeceinDay <= 3){
                this.showReleaseCounts = true;
            }
        }, err => { })
    }

    goToReleaseNotes() {
        this._router.navigate(['app/release-notes']);
    }

    showJobs(val) {
        const url = '/vms/jobs';
        if (val === 'all') {
            this._router.navigate([url]);
        } else if (val === 'invited') {
            this._router.navigate([url + '/invited']);
        }
    }

    downloadFile() {
        let isOpen = false;
        let filePath = '';
        if (this.accessType === 'internal') {
            filePath = '../../../assets/docs/MSP_User_Manual.pdf';
        } else if (this.accessType === 'partner' && this.userTypeSlug === 'standard') {
            isOpen = true;
            filePath = '../../../assets/docs/Serviceo_PMS_Portal_Manual.pdf';
        } else if (this.accessType === 'partner' && this.userTypeSlug === 'admin') {
            isOpen = true;
            filePath = '../../../assets/docs/Serviceo_PMS_Administrator_Portal_Manual.pdf';
        } else if (this.accessType === 'vendor' && this.userTypeSlug === 'standard') {
            filePath = '../../../assets/docs/VMS_User_Manual.pdf';
        } else if (this.accessType === 'vendor' && this.userTypeSlug === 'admin') {
            isOpen = true;
            filePath = '../../../assets/docs/Serviceo_VMS_Administrator_User_Guide.pdf';
        }

        if (filePath) {
            return this._utilityService.downloadAndOpenPdfFile(filePath, 'ServiceO-User Manual.pdf', isOpen);
        } else {
            alert('Help document not found !!');
        }
    }

    getAccount(offset: number, isLoadMore = false) {
        this.loadingIndicator = true;
        this._preloader.showPreloader();
        this.accountApi.find({
            fields: ['id', 'sfdcId', 'Name', 'Company_Reference_code__c', 'Service_Global_Ref__c', 'CreatedById'],
            include: ['users'],
            limit: this.itemsPerBatch,
            where: this.txtAccountName ? { Name: { 'ilike': '%' + this.txtAccountName + '%' }, RecordTypeId: this.recordTypeId } :
                { Name: { 'neq': this.txtAccountName }, RecordTypeId: this.recordTypeId },
            skip: offset
        }).subscribe(accounts => {
            this.noRecords = (accounts.length < this.itemsPerBatch) ? true : false;
            if (!isLoadMore) {
                this.accounts = accounts;
            } else {
                accounts.forEach(c => {
                    this.accounts.push(c);
                });
                this.accounts = [...this.accounts];
            }
            this.setDataTable();
        }, err => {
            this.loadingIndicator = false;
            this._preloader.hidePreloader();
        })
    }

    loadMoreRecords() {
        if (this.programSelection) {
            this.getProgramList(this.accounts.length, true);
        } else {
            this.getAccount(this.accounts.length, true);
        }
    }

    setDataTable() {
        let targettedData = this.isVendorWorkerSelected ? this.workersList : this.accounts;
        if (this.txtAccountName) {
            const val = this.txtAccountName.toLowerCase();
            this.tableData = targettedData.filter(function (d) {
                return d.Name.toLowerCase().indexOf(val) !== -1 || !val;
            });
        } else {
            this.tableData = targettedData;
        }
        this.loadingIndicator = false;
        this._preloader.hidePreloader();
    }

    selectPartner(partnerObj, offset: number) {
        this.partnerInfo = partnerObj;
        this.getProgramList(0, false)
    }

    getProgramList(offset: number, isLoadMore = false) {
        const filter = {
            where: this.txtAccountName ? { Name: { 'ilike': '%' + this.txtAccountName + '%' }, Account__c: this.partnerInfo['sfdcId'], RecordTypeId: '0121a0000006QJJAA2' } :
                { Name: { 'neq': this.txtAccountName }, Account__c: this.partnerInfo['sfdcId'], RecordTypeId: '0121a0000006QJJAA2' },
            fields: ['id', 'sfdcId', 'Name', 'Project__c'],
            limit: this.itemsPerBatch,
            skip: offset
        }
        this._projectApi.find(filter).subscribe(accounts => {
            this.programSelection = true;
            this.noRecords = (accounts.length < this.itemsPerBatch) ? true : false;
            if (!isLoadMore) {
                this.accounts = accounts;
            } else {
                this.accounts = [...this.accounts, ...accounts];
            }
            this.setDataTable();
        }, err => { });
    }

    exitViewAsMode() {
        localStorage.removeItem('viewAsInfo');
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        delete filterObj['accounts'];
        delete filterObj['programs']
        localStorage.setItem('filterObj', JSON.stringify(filterObj));
        delete filterCondition['CKSW_BASE__Account__c'];
        delete filterCondition['Project_SOP__c'];
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
        window.location.replace(window.location.origin + '');
    }

    
    selectToPartnerList() {
        if (this.isVendorWorkerSelected) {
            this.isVendorWorkerSelected = false;
            this.viewAsTypeName = 'Vendor';
            this.setRecordTypeId('Vendor');
        } else {
            this.programSelection = false;
            this.viewAsTypeName = 'Partner';
            this.setRecordTypeId('Partner');
        }
        this.txtAccountName = '';
        this.tableData = [];
    }

    async saveAnalytics() {
        return new Promise((resolve, reject) => {
            this._commonService.saveCurrentPageAnalytics('', '', resolve)
        })
    }

        // to show uploaded image in left panel and heder bar
        pictureUploaded(event) {
            this.user.picture = event.picture;
            this.user.profileImage = event.profileImage;
            this.commonService.setUserProfile(this.user);
        }
    
    redirectTo(row) {
        if ((row && row.user && row.user.contact && row.user.contact.Send_RFI_Email__c == true &&
            row.user.userType && row.user.userType.slug === 'admin') && (row.user.account &&
                row.user.account.Primary_VCSQDLead_Ref__c === null || row.user.account.accountRFI &&
                row.user.account.accountRFI.Iron_Status__c != 'Approved')) {
            window.location.replace(window.location.origin + '/vms/vms-billing-manager?activeId=accountProfile');
        } else if (row && row.user && row.user.userType && row.user.userType.slug !== 'admin' && row.Worker_Type__c.includes('FTE')) {
            window.location.replace(window.location.origin + '/app/workforce-checkin-checkout');
        } else {
            window.location.replace(window.location.origin + '/vms');
        }
    }
    createHelpDeskTask() {
        this._modalService.open(this.createTaskModal, 'xl modal-create-hd-task');
    }

    closeHTpopup(e){
       if(e === true){
        const closeHTPopupBtn = document.getElementById("closeHTPopupBtn");
        closeHTPopupBtn.click();
       }
    }

    checkPartnerIsAdmin(checked) {
        if (checked) {
            this.partnerIsAdmin = true;
        } else {
            this.partnerIsAdmin = false;
        }
      }

      initWorkerSearch() {
        const observable = this.workerKeyUp
            .map(value => value['target']['value'].toLowerCase())
            .debounceTime(300)
            // .distinctUntilChanged()
            .flatMap((search) => {
                return Observable.of(search).delay(300);
            })
            .subscribe((data) => {
                this.getAllWorkerList(0, false);
            });
    }

    showVendorSection(){
        this.showVendorListing = true;
        this.showWorkerListing = false;
    }

    showWorkerSection(){
        this.showWorkerListing = true;
        this.showVendorListing = false;
    }

    getAllWorkerList(offset = 0, isLoadMore = false){
        let searchWorkerCondition = [];
        if(this.workerTxtAccountName) {
            const regex = /(W00)|(@)|(WI)/g;
            this.workerIDOrEmail = this.workerTxtAccountName.match(regex);
            console.log(this.workerIDOrEmail)
            // searchWorkerCondition = [{'firstname': {'like': '%' + this.workerTxtAccountName + '%' }}, {'lastname': {'like': '%' + this.workerTxtAccountName + '%' } }] DUVÁN GABRIEL OSOY MONTERROSO
            let splittedWorkerName = this.workerTxtAccountName.split(" "); 
            splittedWorkerName.forEach(function (value) {
                if(value.length > 0){
                    let firstNameCondition = {'firstname': {'ilike': '%' + value + '%' }};
                    searchWorkerCondition.push(firstNameCondition);
                    let lastNameCondition = {'lastname': {'ilike': '%' + value + '%' }};
                    searchWorkerCondition.push(lastNameCondition);
                }
            }); 
        }
        this.loadingIndicator = true;
        this._preloader.showPreloader();
        this._usersApi.find({
            fields: ['id', 'sfdcId', 'accessType', 'userType', 'userTypeId', 'isTest', 'isMagiclinkTest', 'isNgo', 'firstname', 'lastname', 'AccountId','WorkerSfdcId'],
            include: [ 
                {
                    relation: 'workers',
                    scope: {
                        fields: ['id', 'sfdcId', 'Name', 'Dispatch_Worker_num__c', 'Email__c', 'Contact__c', 'Account__c', 'Account_Name__c', 'Worker_Type__c'],
                        where:
                            this.workerTxtAccountName && this.workerIDOrEmail && this.workerIDOrEmail.length > 0 ?
                                {
                                    and: [
                                        { "sfdcId": { neq: null } },
                                        { or: [{ "Dispatch_Worker_num__c":{'ilike': '%' +  this.workerTxtAccountName+ '%' }}, { "Email__c": {'ilike': '%' + this.workerTxtAccountName+ '%' }}] },
                                    ]
                                } : { "sfdcId": { neq: null } },
                    }
                },
                {
                    relation: 'userType',
                    scope: {
                        fields: ['name', 'slug']
                    }
                },
                {
                    relation: 'account',
                    scope: {
                        fields: ['id', 'Name', 'Primary_VCSQDLead_Ref__c', 'Default_Pricelist__c', 'CurrencyIsoCode'],
                        include: [{
                            relation: 'accountRFI',
                            scope: {
                                fields: ['Iron_Status__c']
                            }
                        }]
                    }
                },
                {
                    relation: 'contact',
                    scope: {
                        fields: ['id', 'Send_RFI_Email__c', 'UserId', 'LastLogin__c', 'ReportsToId', 'Contact_Name__c', 'Department'],
                        include: [{
                            relation: 'reportingManagerList',
                            scope: {
                                fields: ['sfdcId', 'Name']
                            }
                        }]
                    }
                }
            ],
            order: 'firstname', 
            limit: this.itemsPerBatch,
            where: this.workerTxtAccountName && this.workerIDOrEmail==null ? {and: [{"accessType": 'vendor',"sfdcId": { neq: null }}, {or: searchWorkerCondition} ]} : {"accessType": 'vendor',"sfdcId": { neq: null }},
            skip: offset
        }).subscribe(workers => {
            this.totalWorkerCount += workers.length;
            this.noRecords = (workers.length < this.itemsPerBatch) ? true : false;
            let tempWorkers = [];
            workers.forEach(el=>{
                if(el['workers'] && el['workers'].length && el['workers'][0] && el['workers'][0]['sfdcId']) {
                    el['worker'] = el['workers'][0];
                    delete el['workers'];
                    tempWorkers.push(el);
                }
            })
            workers = [...tempWorkers];

            //workers = workers.filter((item: any) => item && item.worker && item.sfdcId);
            //console.log(workers.length)
            if (!isLoadMore) {
                this.allWorkersList = workers;
            } else {
                workers.forEach(c => {
                    this.allWorkersList.push(c);
                });
                this.allWorkersList = [...this.allWorkersList];
            }
            
            this.workerSetDataTable();
        }, err => {
            this.loadingIndicator = false;
            this._preloader.hidePreloader();
        })
    }

    workerSetDataTable() {
        let targettedData = this.allWorkersList; //this.isVendorWorkerSelected ? this.allWorkersList : this.accounts;
        if (this.workerTxtAccountName && this.workerIDOrEmail==null) {
            const val = this.workerTxtAccountName.toLowerCase();
            this.workerTableData = targettedData.filter(function (d) {
                return d.worker.Name.toLowerCase().indexOf(val) !== -1 || !val;
            });
        } else {
            this.workerTableData = targettedData;
        }
        this.loadingIndicator = false;
        this._preloader.hidePreloader();
    }
    
    setVendorWorkerView(vendor) {
        this.vendor = vendor;
        if (vendor) {
            localStorage.setItem('ImpersonationId', vendor['WorkerSfdcId']);
        } else {
            localStorage.removeItem('ImpersonationId');
        }
        this._appState.setNavAsVendor(this.vendor);
    }

    selectWorkerDetail(row) { 
        this.setVendorWorkerView(row);
        let workerProcessRow = {};
        let userRow = {};
        userRow = {user: row};
        workerProcessRow = row.worker;
        let processRow = {...workerProcessRow, ...userRow}; 
        console.log(processRow)
        this.vendorAccessType = {
            viewAsType: this.viewAsTypeName,
            accessType: row['accessType'],
            workerType: row.worker['Worker_Type__c'],
            userTypeSlug: row.userType.slug,
            isMagicLink: row.isMagiclinkTest,
            isNgo: row.isNgo,
            isTest: row.isTest,
            userSfdcId: row.sfdcId,
            accountId: row.worker['Account__c'],
            workerSfdcId: row.WorkerSfdcId,
            firstName: row.firstname,
            lastName: row.lastname,
            userObj: processRow,
        }
        const filterObj = JSON.parse(localStorage.getItem('filterObj'));
        const filterCondition = JSON.parse(localStorage.getItem('filterCondition'));
        filterObj['accounts'] = [row.worker['Account__c']];
        localStorage.setItem('filterObj', JSON.stringify(filterObj));
        filterCondition['Vendor__c'] = { inq: [row.worker['Account__c']] };
        delete filterCondition['Project_SOP__c'];
        localStorage.setItem('filterCondition', JSON.stringify(filterCondition));
        localStorage.setItem('viewAsInfo', JSON.stringify(this.vendorAccessType));

        this._modalService.closed();
        this.redirectToWorkerAccount(row);
    }

    redirectToWorkerAccount(row) {
        if ((row && row.contact && row.contact.Send_RFI_Email__c == true &&
            row.userType && row.userType.slug === 'admin') && (row.account &&
                row.account.Primary_VCSQDLead_Ref__c === null || row.account.accountRFI &&
                row.account.accountRFI.Iron_Status__c != 'Approved')) {
            window.location.replace(window.location.origin + '/vms/vms-billing-manager?activeId=accountProfile');
        } else if (row && row.userType && row.userType.slug !== 'admin' && row.worker.Worker_Type__c.includes('FTE')) {
            window.location.replace(window.location.origin + '/app/workforce-checkin-checkout');
        } else {
            window.location.replace(window.location.origin + '/vms');
        }
    }

    loadMoreWorkerRecords() {
        this.getAllWorkerList(this.totalWorkerCount, true);
    }

    openmodel(template) {
        this._modalService.open(template, { size: 'sm modal-clone-milestone', backdrop: 'static', keyboard: false });
      }
    
}
