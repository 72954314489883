import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { environment } from '../../../environments/environment';
import { UniquePipe } from './../../pipe/unique/unique.pipe';
import { PreloaderService } from '../../services/preloader.service';
import { DocumentApi, UsersApi, JobApi, JobOrderItemApi, IronAttachmentApi, CommentApi } from '../../sdk';
import { CommonService } from 'shared/services/common.service';
import { AppStateService } from 'shared/services/app-state.service';
import { Subject, Subscription } from 'rxjs';
import { ModalService } from 'shared/services/modal.service';
import { SharedService } from 'shared/services/pms/shared.services';
import { fileTypesOpenInBrowser } from 'shared/models/static-list-data.service';
import { UtilityService } from 'shared/services/utility.service';
import { humanizeBytes } from 'ngx-uploader';
import { BaseVendorViewComponent } from 'msp/shared/base-vendor-view/base-vendor-view.component';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.css'],
    providers: [UniquePipe]
})

export class DocumentListComponent extends BaseVendorViewComponent implements OnInit, OnDestroy {

    @Input() modelNames: any;
    @Input() modelIds: any;
    @Input() accountName: string = '';
    @Input() programName: string = '';
    @Input() accountSfdcId: string = '';
    @Input() programSfdcId: string = '';
    @Input() Partner_Case_Number__c: string = '';
    @Input() from: any; // documents belongs to job or jobOrderItem
    @Input() documentsCount: any;
    @Input() commonData: any;
    @Output() deleted = new EventEmitter<any>();
    @Output() docCountUpdated = new EventEmitter<any>();
    @Input() refreshFiles?: any;
    @Input() timeZone: any;
    @Input() pmsCaseSfdcId = '';
    @Input() hideClassForTask;
    @Input() fileManager;
    @Input() fromAccountProfile : boolean = false;
    error: string;
    containerPath: string;
    documents: Array<any>;
    user: any;
    icon: string;
    categories = [];
    displayCategories = {};
    categoriesList: Array<any> = [];
    categoriesActiveIds = [];
    newDocumentList: any = [];
    errorMessage: any;
    documentList: any = {};
    defaultConfig: any = { _itemsPerPage: 25, _deleteDoc: true };
    jobObj: any = {};
    accessType = '';
    viewDetails: String = '';
    deleteDoc: String = '';
    fileType: String = '';
    showJobOrderSelect = false;
    // commonData = {
    //     'workOrderName': '',
    //     'workOrderId': '',
    //     'JobsiteName': ''
    // }

    humanizeBytes: Function;

    serviceManagerConsoleSubscription: Subscription;
    deleteOption:any;
    imgPreview = '';
    userData: any;
    pmcCaseSfdcId='';
    caseId='';
    constructor(
        private _appState: AppStateService,
        private _jobApi: JobApi,
        private _userApi: UsersApi,
        private _documentApi: DocumentApi,
        private _ironAttachmentApi: IronAttachmentApi,
        private _alertService: AlertService,
        private _preloaderService: PreloaderService,
        private _commonService: CommonService,
        private __jobOrderItemApi: JobOrderItemApi,
        private _modalService: ModalService,
        public _sharedService: SharedService,
        public _utilityService: UtilityService,
        private _commentApi: CommentApi,
        private _route: ActivatedRoute
    ) {
        super(_appState);
        this.humanizeBytes = humanizeBytes;
        const filePermissions = this._appState.getFileManagerPermissions();
        this.deleteOption = filePermissions['delete']['allowedSubCategoryList'];
        this._route.queryParams.subscribe(params => {
            console.log(params['id']);
            console.log(params);
            this.pmcCaseSfdcId=params.pmcCase;
            this.caseId = params['recordId'];
        });
    }

    updateData(docs: any) {
        docs.forEach(d => {
            this.documents.unshift(this.tmpModify(d));
        });
        this.GroupCategory();
    }

    ngOnInit() {
        this._commonService.saveCurrentPageAnalytics('Job Manager Console - Details', 'Files');
        console.log('>>>', this.commonData);
        this.documents = [];
        if (!this.from) {
            this.from = 'Job'
        }
        this.accessType = this._appState.getAccessType();
        // To check if view as PMS mode is activated.
        if (!this.viewAsVendor && localStorage.getItem('viewAsInfo')) {
            const viewAsInfo = JSON.parse(localStorage.getItem('viewAsInfo'));
            const viewAsPartner = (viewAsInfo && viewAsInfo.viewAsType === 'Partner');
            this.accessType = (viewAsPartner) ? 'partner' : this.accessType;
        } else if(this.viewAsVendor) {
            this.accessType = 'vendor'
        }
        this.containerPath = environment.baseUrl + '/' + environment.apiVersion + '/Containers/';
        this.error = '';
        if (this.modelNames === 'jobOrderItem') {
            this.showJobOrderSelect = true;
        }
        this.refreshFiles.subscribe(response => {
            if (response) {
                this.loadData();
            }
        });
        this.formDataLoading();
        this.userData = this._appState.getAppState();
    }
    formDataLoading = async () => {
        if (this.documentsCount && this.documentsCount > 0) {
            this.loadData();
            this.docCountUpdated.emit();
        } else {
            const modelArray = this.getModelIds();
            this.documentsCount = await this._utilityService.getDocumentsCount(this.modelNames, modelArray);
            if (this.documentsCount && this.documentsCount > 0) {
                this.loadData();
                this.docCountUpdated.emit();
            } else {
                this.errorMessage = this.getErrorMessage();
            }
        }
    }

    loadData() {
        if (this.modelIds && this.modelNames && this.accessType === 'internal') {
            if (this.modelNames && this.modelNames === 'Job') {
                this.loadJobData(this.modelIds[0]['Job'])
            } else if (this.modelNames && this.modelNames === 'JobOrderItem') {
                this.loadJobOrderItemData(this.modelIds[0]['jobOrderItem'])
            } else {
                this.getDocuments();
            }
        } else {
            this.getDocuments();
        }
    }

    loadJobData(jobId) {
        this.jobObj = {};
        const reqObj = {
            'where': { 'id': { inq: jobId } },
            'fields': ['id', 'sfdcId', 'Vendor__c', 'Dispatch_Worker_Name__c', 'shortUrl'],
            include: [
                {
                    relation: 'worker',
                    scope: {
                        fields: ['id', 'sfdcId', 'Name']
                    }
                },
                {
                    relation: 'vendor',
                    scope: {
                        fields: ['id', 'sfdcId', 'Name']
                    }
                }
            ]
        };
        this._jobApi.getJobDetailsById(reqObj).subscribe(async (result) => {
            if (result && Object.keys(result).length > 0) {
                this.jobObj = result;
                if (this.jobObj.Vendor__c) {
                    this.jobObj.users = await this.getUserData(this.jobObj.Vendor__c);
                }
            }
            this.getDocuments();
        }, error => {
            this.errorMessage = error.message;
        });
    }

    loadJobOrderItemData = (jobOrderItemId) => {
        this.jobObj = {};
        const reqObj = {
            'where': { 'id': { inq: jobOrderItemId } },
            'fields': ['id', 'sfdcId', 'Vendor__c'],
            include: [
                {
                    relation: 'vendor',
                    scope: {
                        fields: ['id', 'sfdcId', 'Name']
                    }

                }
            ]
        };
        this.__jobOrderItemApi.find(reqObj).subscribe(async (result) => {
            if (result && Object.keys(result).length > 0) {
                this.jobObj = result;
                if (this.jobObj[0].Vendor__c) {
                    this.jobObj.users = await this.getUserData(this.jobObj[0].Vendor__c);
                }
            }
            this.getDocuments();
        }, error => {
            this.errorMessage = error.message;
        });
    }

    async getUserData(vendorId) {
        const filter = {
            fields: ['id', 'sfdcId', 'userTypeId', 'firstname', 'lastname'],
            where: {
                AccountId: vendorId,
                userTypeId: 2
            }
        };

        return new Promise((resolve, reject) => {
            this._userApi.find(filter).subscribe(users => {
                resolve(users);
            }, error => {
                this.errorMessage = error.message;
                reject();
            })
        });
    }
    getModelIds = () => {
        let modelIdsArray = [];
        if (this.modelIds) {
            this.modelIds.forEach(element => {
                if (element) {
                    if (element[Object.keys(element)[0]].length > 0) {
                        modelIdsArray = modelIdsArray.concat(element[Object.keys(element)[0]])
                    }
                }
            });
        }
        return modelIdsArray;
    }

    getDocuments() {
        this._preloaderService.showPreloader();
        const modelIdsArray = this.getModelIds();
        const filterObj = {
            where: {
                modelName: this.modelNames,
                modelId: { inq: modelIdsArray },
                isDeleted: 0
            },
            order: 'createdAt desc',
            include: [
                { relation: 'category', scope: { fields: ['title', 'id'] } },
                { relation: 'subCategory', scope: { fields: ['title', 'id'] } }
            ]
        }

        // if (this.accessType === 'partner') {
        //     const currentUser = JSON.parse(localStorage.getItem('appData'));
        //     filterObj.where['ownerId'] = currentUser && currentUser.user && currentUser.user.id;
        // }

        this._documentApi.find(filterObj).subscribe(
            results => {
                this.errorMessage = this.getErrorMessage(results);
                if (results && results.length > 0) {
                    this.documents = this.modifyData(results);
                    this.prepareConfigData();
                } else {
                    this.documents = [];
                }
                // this.GroupCategory();
                this._preloaderService.hidePreloader();
            },
            err => {
                this.errorMessage = err.message;
                this._preloaderService.hidePreloader();
            }
        );
    }

    getErrorMessage = (result?) => {
        if (result && result.length) {
            return ''
        } else {
            return 'No document(s) found.'
        }
    }

    modifyData(data) {
        if (data.length) {
            data.forEach((item) => {
                this.tmpModify(item);
            });
            return data;
        } else {
            return [];
        }
    }

    tmpModify(item) {
        const subCatId = (item && item.subCategory && item.subCategory.id) ? item.subCategory.id : '';
        item['id'] = (item.id) ? item.id : '';
        item['Name'] = (item && item.fileMeta.name) ? item.fileMeta.name : '';
        item['subCategory'] = (item && item.subCategory) ? item.subCategory.title : '';
        item['uploadedBy'] = item.uploadedBy;
        item['isDeleteAllowed'] = (this.deleteOption.indexOf(subCatId)!==-1) ? true : false;
        // item['createdAt'] =new Date(item.createdAt).toLocaleString();
        //item['createdAt'] = item.createdAt;
        item['createdAt'] = item.createdAt ? this._commonService.applyNewTimeZone(item.createdAt, this.timeZone) : null
        item['fileMeta'] = (item && item.fileMeta) ? item.fileMeta : '';
        item['shortUrl'] = (item && item.shortUrl) ? item.shortUrl : '';
        item['description'] = (item && item.description) ? item.description : '';
        item['size'] = (item && item.fileMeta && item.fileMeta.size) ? this.humanizeBytes(item.fileMeta.size) : '';
        item['fileType'] = (item['Name'].length > 0) ? this.getExtension(item['Name']) : '';

        item['program'] = (item && item.context && item.context.programName) ? item.context.programName : ''
        item['account'] = (item && item.context && item.context.accountName) ? item.context.accountName : ''
        item['category'] = (item && item.category && item.category.title) ? item.category.title : ''
        item['department'] = (item && item.context && item.context.departmentName) ? item.context.departmentName : ''
        item['workOrderNumber'] = (this.commonData && this.commonData.workOrderName) ? this.commonData.workOrderName : ''
        item['jobId'] = (this.commonData && this.commonData.jobId) ? this.commonData.jobId : ''
        item['jobSite'] = (this.commonData && this.commonData.JobsiteName) ? this.commonData.JobsiteName : ''

        if (this.accessType === 'internal') {
            // set vendor name
            item['vendorName'] = (this.jobObj.vendor && this.jobObj.vendor.Name) ? this.jobObj.vendor.Name : '';
            // set worker name
            let workerName = '';
            if (this.jobObj.worker && this.jobObj.worker.Name) {
                workerName = this.jobObj.worker.Name + ' (Assigned Worker)';
            }
            if (this.jobObj.users && this.jobObj.users.length) {
                this.jobObj.users.forEach(user => {
                    workerName = workerName ? (workerName + ', ') : workerName;
                    workerName += (user.firstname + ' ' + user.lastname + ' (Admin)');
                });
            }
            item['workerName'] = workerName;
        }
        return item;
    }

    getExtension = (name) => {
        const parts = name.split('.');
        return parts[parts.length - 1]
    }

    prepareConfigData() {

        // TODO: COMMENTING SELECTED AND ACTION PROP
        this.defaultConfig['_columns'] = [];
        let configList = [
            {
                name: 'Document Title',
                prop: 'subCategory',
                minWidth: 200,
                showMore: true,
                width: 200,
                sortable: true,
                resizeable: false,
                visible: true,
                show: ['Job', 'jobOrderItem', 'Task', 'milestoneLibrary']
            },
            {
                name: 'File Name',
                prop: 'Name', minWidth:
                    200, width: 200,
                sortable: true,
                resizeable: false,
                visible: true,
                show: ['Job', 'jobOrderItem', 'Task', 'milestoneLibrary']
            },
            // { name: 'Description', prop: 'description', show=['Job']},
            { name: 'Short URL', prop: 'shortUrl', show: ['Job', 'jobOrderItem', 'Task', 'milestoneLibrary'] },
            { name: 'Account', prop: 'account', show: ['jobOrderItem', 'Task', 'milestoneLibrary'] },
            { name: 'Program', prop: 'program', show: ['jobOrderItem', 'Task'] },
            { name: 'Description', prop: 'description', show: ['jobOrderItem', 'Task', 'milestoneLibrary'] },
            { name: 'Document Category', prop: 'category', show: ['jobOrderItem', 'Task', 'milestoneLibrary'] },
            { name: 'Work Order Number', prop: 'workOrderNumber', show: ['jobOrderItem'] },
            // { name: 'Date Uploaded', prop: 'createdAt', show: ['jobOrderItem'] },
            { name: 'Sub Category', prop: 'subCategory', show: ['jobOrderItem'] },
            { name: 'Updated by', prop: 'uploadedBy', show: ['jobOrderItem', 'Task', 'milestoneLibrary'] },
            { name: 'Job ID', prop: 'jobId', show: ['jobOrderItem'] },
            { name: 'Job Sites', prop: 'jobSite', show: ['jobOrderItem'] },
            { name: 'size', prop: 'size', show: ['Task', 'milestoneLibrary'] },
        ];

        if (this.accessType === 'internal') {
            configList.push({
                name: 'Access (Vendor Name)', prop: 'vendorName', show: ['Job', 'jobOrderItem']
            });
            configList.push({
                name: 'Access (Worker Name)', prop: 'workerName', show: ['Job']
            });
        };
        configList.push(
            {
                name: 'Date Uploaded', prop: 'createdAt', show: ['Job', 'jobOrderItem', 'Task', 'milestoneLibrary']
            },
        );
        // configList.forEach(config => {
        //     if (config && config.show && config.show.indexOf(this.from) !== -1 && this.documents[0][config.prop]) {
        //         this.defaultConfig['_columns'].push(config)
        //     }
        // })
        if (this.documents && this.documents.length) {
            this.documents.forEach(doc => {
                doc['defalultConfig'] = [];
                configList.forEach(config => {
                    if (config && config.show && config.show.indexOf(this.from) !== -1 && doc[config.prop]) {
                        // this.defaultConfig['_columns'].push(config)
                        doc['defalultConfig'].push(config)
                    }
                })
            })
        }
    }

    GroupCategory() {
        this.categoriesList = [];
        this.categoriesActiveIds = [];
        this.documents.map(item => {
            if (item.category) {
                this.categoriesList.push({
                    id: item.categoryId,
                    Name: item.category.title
                });
            }

            this.categoriesActiveIds.push(item.categoryId);
        });
        this.categoriesList = new UniquePipe().transform(this.categoriesList, 'id');
        this.categoriesList.forEach(item => {
            item['data'] = this.docFilter(item.id);
        });
    }

    docFilter(categoryId) {
        return this.documents.filter(
            data => data.categoryId && data.categoryId === categoryId
        );
    }

    deleteDocument(res) {
        this.deleted.emit(res);
    }

    onActionClick(file) {
        const filePath = this.containerPath + file.fileMeta.path;
        this._commonService.download(filePath, file.fileMeta.name);
    }

    refreshDataTable() {
        // alert('Reloading the data....');
        this.getDocuments();
        this.docCountUpdated.emit();
    }

    initiateViewDetails = (doc) => {
        if (doc && doc.uuid && this.viewDetails !== doc.uuid) {
            this.viewDetails = doc.uuid;
        } else {
            this.viewDetails = '';
        }
    }
    initiateDeleteDocumet = (doc) => {
        if (doc && doc.uuid && this.deleteDoc !== doc.uuid) {
            this.deleteDoc = doc.uuid;
        } else {
            this.deleteDoc = '';
        }
    }
    confirmDelete = (doc) => {
        if (doc === 'cancel') {
            this.deleteDoc = '';
        } else {
            if (doc) {
                this._preloaderService.showPreloader();
                this._documentApi.deleteByIds({ ids: [doc.id] }).subscribe(
                    res => {
                        if (res) {
                            this._alertService.clear();
                            this._alertService.success(
                                'Document(s) deleted successfully.'
                            );
                            // this.deleteDoc.emit(res);
                            this.deleteDocument(res);
                            this.refreshDataTable()
                        }
                        this._preloaderService.hidePreloader();
                        this.deleteDoc = '';
                    },
                    err => {
                        this.deleteDoc = '';
                        this._alertService.error(err.message);
                        this._preloaderService.hidePreloader();
                    }
                );
            }
        }
    }

    /**
     * Method to download the document.
     * @param doc - the document object that to be download
     * @param type - type to handle the case of open the document in browser new tab.
     * The argument @param type is by default 0, represent download by defalut. 
     */
    downloadDocument = (doc, type = 0) => {
        let fileTypesOpenInBrowserList = fileTypesOpenInBrowser;
        if (type != 0) {
            // doc.fileType
            if (doc && doc.fileMeta && doc.fileMeta.extension in fileTypesOpenInBrowserList) {
                if (doc.shortUrl && doc.shortUrl) {
                    window.open(doc.shortUrl, '_blank');
                    return;
                }
            }
        }
        if (doc) {
            const item = doc;
            let files = [];
            files.push({
                path: this.containerPath + (item.fileMeta.path),
                name: item.fileMeta.name
            });
            this._commonService.downloadMultiFiles(files);
        }
    }

    openUloadModal = (template) => {
        this._modalService.open(template, 'lg');
    }
    documentUploaded = (event,from?) => {
        console.log(from)
        console.log('event::',event)
        // this.docCountUpdated.next();
        // this.loadData();
        this.refreshDataTable();
        const sendMessage = (from==='jobOrderItem') ? true :false;
        if (event && event.length && sendMessage) {
            // append description
            const desc = event[0]['description'];
            let contentText = desc ? '<span>' + desc + '</span>' : '';
            let count = 0;
            event.forEach(file => {
                if (file && file.shortUrl) {
                    count++;
                    const shortUrl = file.shortUrl;
                    contentText += '<br/><a href="' + shortUrl + '" target="_blank">' + shortUrl + '</a>';
                    if (file['fileMeta'] && file['fileMeta']['type'].match('image')) {
                        this.imgPreview = '<img src="' + shortUrl + '" /><br/>';
                    }
                    contentText += (this.imgPreview) ? ('<br/>' + this.imgPreview) : '';
                }
            });
            const currentUser = this.userData.getValue().user;

            // parter and internal can see
            // when modelname is JobOrderItem then save ModelName as Case
            const userTypeCheckForJobMsg = (currentUser.accessType === 'partner' || currentUser.accessType ==='internal') ? true : false;
            const param = {
                modelName: (event[0]['modelName']==='Job') ? 'Job' : 'Case',
                modelId:(currentUser.accessType === 'partner') ? this.caseId : event[0]['modelId'],
                messageType: 'worker-visit-status',
                Is_Public_Job__c: userTypeCheckForJobMsg,
                Is_Public__c: false,// as we dont have rooms
                isPrivate: false,// as we dont have rooms
                Case_Comment_Body__c: contentText.split('\n').join('<br>'),
                postByName: currentUser['email'],
                ownerId: currentUser['id'],
                Contact__c: currentUser['sfdcId'],
                parentSfdcId:this.pmcCaseSfdcId
            };
            this._preloaderService.showPreloader();
            this._commentApi.sendMessage(param).subscribe(
                result => {
                    this._preloaderService.hidePreloader();
                },
                err => {
                    if (err.statusCode === '413') {
                        err.message = 'Message size too large!';
                    }
                    // this._alertService.error(err.message);
                    // window.scrollTo(0, 0);
                    this._preloaderService.hidePreloader();
                }
            );
        }
    }

    showAttachFile = () => {
        if (this.from && (this.from === 'jobOrderItem' || this.from === 'Task' || this.from === 'milestoneLibrary')) {
            const jobIdLength = this.modelIds && this.modelIds[0] && this.modelIds[0]['Job'] && this.modelIds[0]['Job'].length || 0;
            const jobOrderIdLength = this.modelIds && this.modelIds[0] && this.modelIds[0]['jobOrderItem'] &&
                this.modelIds[0]['jobOrderItem'].length || 0;
            const taskIdLength = this.modelIds && this.modelIds[0] && this.modelIds[0]['Task'] && this.modelIds[0]['Task'].length || 0;
            const milestoneLibraryIdLength =
                this.modelIds &&
                this.modelIds[0] &&
                this.modelIds[0]['MilestoneLibrary'] &&
                this.modelIds[0]['MilestoneLibrary'].length || 0;
            if (jobIdLength > 0 || jobOrderIdLength > 0 || taskIdLength > 0 || milestoneLibraryIdLength > 0) {
                return true;
            }
            return false;
        }
        return false;
    }

    ngOnDestroy() {
        // this.serviceManagerConsoleSubscription && this.serviceManagerConsoleSubscription.unsubscribe();
    }


}
